import React from 'react';
import {AutocompleteInput} from 'react-admin';

/**
 * An autocomplete component with selection arrow
 */
const AutocompleteSelect = (props) => {
    const {
        allowEmpty,
        className,
        choices,
        disabled,
        isRequired,
        label,
        onChange,
        source,
        validate,
        emptyText = '',
        returnValueType = 'value'
    } = props;

    const handleChange = (e) => {
        let value = (e && e.id) ? e.id : e;
        if (returnValueType === 'object') {
            const idx = choices.findIndex(r => r.id === value);
            value = (idx !== -1) ? choices[idx] : null;
        }
        if (typeof onChange === 'function') {
            onChange(value, source);
        }
    };

    return (
        <span className={(className) ? className : undefined}>
            <span className="get-list-select-input">
                <AutocompleteInput
                    source={source}
                    label={label}
                    optionText="display_name"
                    emptyText={emptyText}
                    choices={choices}
                    allowEmpty={allowEmpty}
                    required={isRequired}
                    options={{
                        placeholder: label,
                        required: isRequired,
                        suggestionsContainerProps: {
                            modifiers: {
                                computeStyle: {
                                    enabled: true,
                                    gpuAcceleration: false
                                },
                                applyStyle: {
                                    enabled: true,
                                }
                            }
                        }
                    }}
                    disabled={(disabled) ? disabled : undefined}
                    validate={(validate) ? validate : undefined}
                    onChange={(e) => handleChange(e)}
                />
                <svg className="MuiSvgIcon-root MuiSelect-icon MuiSelect-iconFilled arrow" focusable="false"
                     viewBox="0 0 24 24" inert="true" aria-hidden="true">
                    <path d="M7 10l5 5 5-5z"/>
                </svg>
            </span>
        </span>
    );
};

export default AutocompleteSelect;
