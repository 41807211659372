import {
    calculateBillingCosts,
    formatBillingCosts,
    formatLaborTime,
    setItemDateValues,
    stripWhiteSpace
} from './utils/dataFunctions';
import {RESOURCES as resources} from './productResources';

/**
 * Pre-process incoming single item data consisting of an array
 *
 * @param resource
 * @param type
 * @param params
 * @param rdata
 * @returns {{location: null, locations: *[], id: null}}
 */
export const preProcessSingleListData = (resource, type, params, rdata) => {
    if (resource === 'WarehouseLocation') {
        rdata = prePocessGetWarehouseLocationData(rdata);
    }
    return rdata;
};

/**
 * Post-Process incoming list or item data
 *
 * @param resource
 * @param type
 * @param params
 * @param rdata
 *
 * @return {*}
 */
export const postProcessData = (resource, type, params, rdata) => {

    if (resource === 'ClientUser' && type === 'GET_ONE') {
        rdata = processGetClientUserData(rdata);
    }
    if (resource === 'Contact' && type === 'GET_LIST') {
        rdata = processGetContactsData(rdata);
    }
    if (resource === 'Order') {
        if (type === 'GET_LIST') {
            rdata = processGetOrdersData(rdata);
        }
        if (type === 'GET_ONE') {
            rdata = processGetOrderData(rdata);
        }
    }
    if (resource === 'Product') {
        if (type === 'GET_LIST') {
            rdata = processGetProductsData(rdata);
        }
        if (type === 'GET_ONE') {
            rdata = processGetProductData(rdata);
        }
    }
    if (resource === 'Receipt') {
        if (type === 'GET_LIST') {
            rdata = processGetReceiptsData(rdata);
        }
        if (type === 'GET_ONE') {
            rdata = processGetReceiptData(rdata);
        }
    }
    if (resource === 'ShippingMethod' && type === 'GET_ONE') {
        rdata = processGetShippingMethodData(rdata);
    }
    if (resource === 'Show' && type === 'GET_ONE') {
        rdata = processGetShowData(rdata);
    }
    return rdata;
};

/* ID and Key exported functions ::::: */

/**
 * Mark resources without ids or with different unique identifiers
 */
export const setUniqueKeys = (resource) => {
    const parseParams = {
        hasNameKeys: false,
        needsId: false,
        parentKey: null,
        idKey: null
    };
    if (resource === 'ClientUser') {
        parseParams.idKey = 'username';
    }
    if (resource === 'ProductBySku') {
        parseParams.idKey = 'sku';
    }
    if (resource === 'WarehouseLocation') {
        parseParams.idKey = 'location';
    }
    return parseParams;
};

/**
 * Convert GET_ALL responses that are formatted as an object with totals
 */
export const getListFromDataObject = (resource, type, rdata) => {
    if (resource === 'Order' && type === 'GET_LIST') {
        return {
            list: (rdata.orders && rdata.orders.length > 0) ? rdata.orders : [],
            total: rdata.total
        }
    }
    if (resource === 'Receipt' && type === 'GET_LIST') {
        return {
            list: (rdata.receipts && rdata.receipts.length > 0) ? rdata.receipts : [],
            total: rdata.total
        }
    }
    return {
        list: rdata,
        total: rdata.length
    }
};

/* Processing for Specific Resources ::::: */

const processGetClientUserData = (rdata) => {
    return rdata;
};

const processGetContactsData = (rdata) => {
    if (!rdata.data || !rdata.data.length) {
        return rdata;
    }
    // Remove extra spaces from Contact fields
    rdata.data.forEach(function (item, idx) {
        item.name = stripWhiteSpace(item.name);
        item.first_name = stripWhiteSpace(item.first_name);
        item.last_name = stripWhiteSpace(item.last_name);
        item.city = stripWhiteSpace(item.city);
        if (item.name === '') {
            item.name = item.first_name + ' ' + item.last_name;
        }
    });

    return rdata;
};

const processGetOrdersData = (rdata) => {
    if (!rdata.data || !rdata.data.length) {
        return rdata;
    }
    // Convert Modified Date values to readable string
    rdata.data.forEach(function (item, idx) {
        item.billed_on_date = '';
        item.billed_on_string = '';
        if (item.billed_on) {
            const billedOn = setItemDateValues(item.billed_on);
            item.billed_on_date = billedOn.date_item;
            item.billed_on_string = billedOn.date_string;
        }
        item.clear_bill_ref_id = false;
        item.ship_to_name = (item.ship_to.name) ? item.ship_to.name : '';

        item.created_on_date = '';
        item.created_on_string = '';
        let createdOn;
        if (item.created_on) {
            createdOn = setItemDateValues(item.created_on);
            item.created_on_date = createdOn.date_item;
            item.created_on_string = createdOn.date_string;
        } else if (item.modified) {
            createdOn = setItemDateValues(item.modified);
            item.created_on_date = createdOn.date_item;
            item.created_on_string = createdOn.date_string;
        }
        if (item.modified) {
            const modifiedDate = setItemDateValues(item.modified);
            item.modified_date = modifiedDate.date_item;
            item.modified_string = modifiedDate.date_string;
        }
        item.invoice_date_date = '';
        item.invoice_date_string = '';
        if (item.invoice_date) {
            const invoiceDate = setItemDateValues(item.invoice_date);
            item.invoice_date_date = invoiceDate.date_item;
            item.invoice_date_string = invoiceDate.date_string;
        }
        item.required_by_date = '';
        item.required_by_string = '';
        if (item.required_by) {
            const requiredByDate = setItemDateValues(item.required_by);
            item.required_by_date = requiredByDate.date_item;
            item.required_by_string = requiredByDate.date_string;
        }
        item.shipped_on_date = '';
        item.shipped_on_string = '';
        if (item.shipped_on) {
            const shippedOnDate = setItemDateValues(item.shipped_on);
            item.shipped_on_date = shippedOnDate.date_item;
            item.shipped_on_string = shippedOnDate.date_string;
        }
        const formattedCosts = formatBillingCosts(item);
        item.courier_cost = formattedCosts.courier;
        item.courier_cost_str = formattedCosts.courier_str;
        item.labor_cost = formattedCosts.labor;
        item.labor_cost_str = formattedCosts.labor_str;
        item.material_cost = formattedCosts.material;
        item.material_cost_str = formattedCosts.material_str;
        item.shipping_cost = formattedCosts.shipping;
        item.shipping_cost_str = formattedCosts.shipping_str;
        item.total_cost = calculateBillingCosts('order', item);
    });
    return rdata;
};

const processGetOrderData = (rdata) => {
    // Convert Modified Date values to readable string
    rdata.data.billed_on_date = '';
    rdata.data.billed_on_string = '';
    if (rdata.data.billed_on) {
        const billedOn = setItemDateValues(rdata.data.billed_on);
        rdata.data.billed_on_date = billedOn.date_item;
        rdata.data.billed_on_string = billedOn.date_string;
    }
    rdata.data.clear_bill_ref_id = false;
    rdata.data.ship_to_name = (rdata.data.ship_to.name) ? rdata.data.ship_to.name : '';

    rdata.data.created_on_date = '';
    rdata.data.created_on_string = '';
    if (rdata.data.created_on) {
        const createdOn = setItemDateValues(rdata.data.created_on);
        rdata.data.created_on_date = createdOn.date_item;
        rdata.data.created_on_string = createdOn.date_string;
    }
    if (rdata.data.modified) {
        const modifiedDate = setItemDateValues(rdata.data.modified);
        rdata.data.modified_date = modifiedDate.date_item;
        rdata.data.modified_string = modifiedDate.date_string;
    }
    rdata.data.invoice_date_date = '';
    rdata.data.invoice_date_string = '';
    if (rdata.data.invoice_date) {
        const invoiceDate = setItemDateValues(rdata.data.invoice_date);
        rdata.data.invoice_date_date = invoiceDate.date_item;
        rdata.data.invoice_date_string = invoiceDate.date_string;
    }
    rdata.data.required_by_date = '';
    rdata.data.required_by_string = '';
    if (rdata.data.required_by) {
        const requiredByDate = setItemDateValues(rdata.data.required_by);
        rdata.data.required_by_date = requiredByDate.date_item;
        rdata.data.required_by_string = requiredByDate.date_string;
    }
    rdata.data.shipped_on_date = '';
    rdata.data.shipped_on_string = '';
    if (rdata.data.shipped_on) {
        const shippedOnDate = setItemDateValues(rdata.data.shipped_on);
        rdata.data.shipped_on_date = shippedOnDate.date_item;
        rdata.data.shipped_on_string = shippedOnDate.date_string;
    }

    rdata.data.set_billed = rdata.data.billed;
    rdata.data.set_billed_by = rdata.data.billed_by_name;

    // Convert time and currency values
    const laborTime = formatLaborTime(rdata.data);
    rdata.data.labor_time = laborTime.time;
    rdata.data.labor_time_hours = laborTime.hours;
    rdata.data.labor_time_mins = laborTime.mins;

    const formattedCosts = formatBillingCosts(rdata.data);
    rdata.data.courier_cost = formattedCosts.courier;
    rdata.data.courier_cost_str = formattedCosts.courier_str;
    rdata.data.labor_cost = formattedCosts.labor;
    rdata.data.labor_cost_str = formattedCosts.labor_str;
    rdata.data.material_cost = formattedCosts.material;
    rdata.data.material_cost_str = formattedCosts.material_str;
    rdata.data.shipping_cost = formattedCosts.shipping;
    rdata.data.shipping_cost_str = formattedCosts.shipping_str;

    return rdata;
};

const processGetReceiptsData = (rdata) => {
    if (!rdata.data || !rdata.data.length) {
        return rdata;
    }
    // Convert Modified Date values to readable string
    rdata.data.forEach(function (item, idx) {
        item.billed_on_date = '';
        item.billed_on_string = '';
        if (item.billed_on) {
            const billedOn = setItemDateValues(item.billed_on);
            item.billed_on_date = billedOn.date_item;
            item.billed_on_string = billedOn.date_string;
        }

        item.created_on_date = '';
        item.created_on_string = '';
        let createdOn;
        if (item.created_on) {
            createdOn = setItemDateValues(item.created_on);
            item.created_on_date = createdOn.date_item;
            item.created_on_string = createdOn.date_string;
        } else if (item.modified) {
            createdOn = setItemDateValues(item.modified);
            item.created_on_date = createdOn.date_item;
            item.created_on_string = createdOn.date_string;
        }
        if (item.modified) {
            const modifiedDate = setItemDateValues(item.modified);
            item.modified_date = modifiedDate.date_item;
            item.modified_string = modifiedDate.date_string;
        }
        item.invoice_date_date = '';
        item.invoice_date_string = '';
        if (item.invoice_date) {
            const invoiceDate = setItemDateValues(item.invoice_date);
            item.invoice_date_date = invoiceDate.date_item;
            item.invoice_date_string = invoiceDate.date_string;
        }
        if (!item.approved_by) {
            item.approved_by = '';
        }
        item.set_approved = item.approved;
        const formattedCosts = formatBillingCosts(item);
        item.courier_cost = formattedCosts.courier;
        item.courier_cost_str = formattedCosts.courier_str;
        item.labor_cost = formattedCosts.labor;
        item.labor_cost_str = formattedCosts.labor_str;
        item.material_cost = formattedCosts.material;
        item.material_cost_str = formattedCosts.material_str;
        item.receiving_cost = formattedCosts.receiving;
        item.receiving_cost_str = formattedCosts.receiving_str;
        item.total_cost = calculateBillingCosts('receipt', item);
    });
    return rdata;
};

const processGetReceiptData = (rdata) => {
    // Convert Modified Date values to readable string
    rdata.data.billed_on_date = '';
    rdata.data.billed_on_string = '';
    if (rdata.data.billed_on) {
        const billedOn = setItemDateValues(rdata.data.billed_on);
        rdata.data.billed_on_date = billedOn.date_item;
        rdata.data.billed_on_string = billedOn.date_string;
    }

    rdata.data.created_on_date = '';
    rdata.data.created_on_string = '';
    if (rdata.data.created_on) {
        const createdOn = setItemDateValues(rdata.data.created_on);
        rdata.data.created_on_date = createdOn.date_item;
        rdata.data.created_on_string = createdOn.date_string;
    }
    if (rdata.data.modified) {
        const modifiedDate = setItemDateValues(rdata.data.modified);
        rdata.data.modified_date = modifiedDate.date_item;
        rdata.data.modified_string = modifiedDate.date_string;
    }
    rdata.data.invoice_date_date = '';
    rdata.data.invoice_date_string = '';
    if (rdata.data.invoice_date) {
        const invoiceDate = setItemDateValues(rdata.data.invoice_date);
        rdata.data.invoice_date_date = invoiceDate.date_item;
        rdata.data.invoice_date_string = invoiceDate.date_string;
    }

    rdata.data.set_billed = rdata.data.billed;
    rdata.data.set_billed_by = rdata.data.billed_by_name;

    // Convert currency values
    const formattedCosts = formatBillingCosts(rdata.data);
    rdata.data.courier_cost = formattedCosts.courier;
    rdata.data.courier_cost_str = formattedCosts.courier_str;
    rdata.data.labor_cost = formattedCosts.labor;
    rdata.data.labor_cost_str = formattedCosts.labor_str;
    rdata.data.material_cost = formattedCosts.material;
    rdata.data.material_cost_str = formattedCosts.material_str;
    rdata.data.receiving_cost = formattedCosts.receiving;
    rdata.data.receiving_cost_str = formattedCosts.receiving_str;

    // Convert time and currency values
    const laborTime = formatLaborTime(rdata.data);
    rdata.data.labor_time = laborTime.time;
    rdata.data.labor_time_hours = laborTime.hours;
    rdata.data.labor_time_mins = laborTime.mins;

    rdata.data.set_approved = rdata.data.approved;
    rdata.data.approved_by = rdata.data?.approved_by ?? '';
    rdata.data.set_approved_by = '';

    return rdata;
};

const processGetProductsData = (rdata) => {
    if (!rdata.data || !rdata.data.length) {
        return rdata;
    }
    rdata.data.forEach(function (item, idx) {
        // Add Display Categories
        let type_display_name = item?.type_name ?? '';
        if (item.parent_type_name && item.type_name) {
            type_display_name = item.parent_type_name + ' > ' + type_display_name;
        }
        item.type_display_name = type_display_name;

        // Get Image path

        const imageBase = resources.ProductImage.GET_ONE({image_path: item.thumbnail});
        item.image_path = (item.thumbnail && item.thumbnail !== '') ? imageBase.uri : null;

        // Convert date values to readable strings
        if (item.modified) {
            const modifiedDate = setItemDateValues(item.modified);
            item.modified_date = modifiedDate.date_item;
            item.modified_string = modifiedDate.date_string;
        }

        // Exclude Warehouse Locations with 0 Qty
        if (item.warehouse_locations) {
            let locations = [];
            const lTotal = item.warehouse_locations.length;
            if (lTotal > 0) {
                for (let w = 0; w < lTotal; w++) {
                    locations.push(item.warehouse_locations[w]);
                }
            }
            item.warehouse_locations = JSON.parse(JSON.stringify(locations));
        }
    });
    return rdata;
};

const processGetProductData = (rdata) => {
    let type_display_name = rdata.data?.type_name ?? '';
    if (rdata.data.parent_type_name && rdata.data.type_name) {
        type_display_name = rdata.data.parent_type_name + ' > ' + type_display_name;
    }
    rdata.data.type_display_name = type_display_name;
    if (rdata.data.org_id) {
        rdata.data.selected_org_id = rdata.data.org_id;
        rdata.data.current_org_id = rdata.data.org_id;
    }
    if (rdata.data.show_id) {
        rdata.data.selected_show_id = rdata.data.show_id;
        rdata.data.current_show_id = rdata.data.show_id;
    }
    if (rdata.data.warehouse_locations) {
        let locations = [];
        const lTotal = rdata.data.warehouse_locations.length;
        if (lTotal > 0) {
            for (let w = 0; w < lTotal; w++) {
                locations.push(rdata.data.warehouse_locations[w]);
            }
        }
        rdata.data.warehouse_locations = JSON.parse(JSON.stringify(locations));
    }
    rdata.data.inventory_adjustment = null;
    rdata.data.adjustment_note = '';
    rdata.data.adjustment_qty = rdata.data.qty;
    rdata.data.adjustment_difference = 0;

    return rdata;
};

const processGetShippingMethodData = (rdata) => {
    if (rdata.data.company_id) {
        rdata.data.current_company_id = rdata.data.company_id;
    }
    return rdata;
};

const processGetShowData = (rdata) => {
    if (rdata.data.org_id) {
        rdata.data.current_org_id = rdata.data.org_id;
    }
    return rdata;
};

const prePocessGetWarehouseLocationData = (rdata) => {
    let data = {
        location: null,
        products: []
    };
    if (rdata.length > 0) {
        data = {
            location: rdata[0].location,
            location_id: rdata[0].id,
            products: rdata
        };
    }

    return data;
};
