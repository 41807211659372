import React, {useState} from 'react';
import {createPortal} from 'react-dom';
import {Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {Field, Form} from 'react-final-form';
import {ListButton, TextInput, TopToolbar} from 'react-admin';
import classnames from 'classnames';
import Emitter from '../utils/eventEmitter';
import FieldDescription from '../components/FieldDescription';
import FieldDivider from '../components/FieldDivider';
import FieldMessage from '../components/FieldMessage';
import ProductCreateDialog from '../components/ProductCreateDialog';
import ReceiptItemsAvailableListContainer from '../components/ReceiptItemsAvailableListContainer';
import ReceiptItemsList from '../components/ReceiptItemsList';
import SelectFromListInput from '../components/SelectFromListInput';
import {assembleDefaultQueryString} from '../utils/dataFunctions';
import {
    getStoredOrderById,
    removeStoredOrder,
    updateStoredOrders
} from '../utils/orderReceiptStorageFunctions';
import {postItem, postReceipt} from '../utils/apiReceiptFunctions';
import {checkAuthentication} from '../utils/authFunctions';
import {viewStyles} from '../global/styles';
import {makeStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ReceiptsIcon from '@material-ui/icons/Receipt';
import '../css/admin.css';
import StoredOrdersReceipts from '../components/StoredOrdersReceipts';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: '#ffffff',
        borderRadius: 4,
        boxShadow: '0 2px 1px -1px rgba(0,0,0,0.2), 0 1px 1px 0 rgba(0,0,0,0.14), 0 1px 3px 0 rgba(0,0,0,0.12)'
    },
    appBar: {
        backgroundColor: '#9e9e9e',
        boxShadow: 'none',
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4
    },
    itemSelect: {
        display: 'inline-flex',
        width: 'calc(100% - 200px)'
    },
    addNewButton: {
        margin: '0 1em 1em 0'
    },
    tabIndicator: {
        backgroundColor: '#508242',
        height: 3
    },
    toolbar: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'flex-start',
        backgroundColor: 'transparent',
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(1),
        minHeight: theme.spacing(5),
        [theme.breakpoints.up('xs')]: {
            paddingLeft: 0,
            paddingRight: 0
        },
        [theme.breakpoints.down('sm')]: {
            paddingRight: theme.spacing(2)
        },
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(1)
        }
    }
}));

// Add a top toolbar with a List button
const CreateToolbar = props => {
    const classes = useStyles(props);
    const defaultOrderQueryString = assembleDefaultQueryString('Receipt');
    return (
        <TopToolbar {...props} className={classnames(classes.toolbar)}>
            <ListButton basePath={`/Receipt${defaultOrderQueryString}`}/>
        </TopToolbar>
    )
};

// Add an AppBar title
const CreateTitle = () => {
    const container = (typeof document !== 'undefined') ? document.getElementById('react-admin-title') : null;
    if (!container) return null;
    const titleElement = (
        <span><Avatar style={viewStyles.avatar} className="avatar-title"
                      alt="Add a Receipt"><ReceiptsIcon
            style={viewStyles.avatarSvg}/></Avatar>Add a Receipt</span>
    );
    return createPortal(titleElement, container);
};

/**
 * Form: Create Receipt with Product Manipulation
 *
 * @param props
 * @param keycloakReady
 * @param keycloakAuthenticated
 * @param tokens
 * @return {JSX.Element}
 * @constructor
 */
function ReceiptCreateForm({props, keycloakReady, keycloakAuthenticated, tokens}) {

    // This page requires authentication
    let isAuthenticated = checkAuthentication(tokens.token, keycloakAuthenticated);
    if (keycloakReady && !isAuthenticated) {
        Emitter.emit('updateKeycloak');
    }

    // State: Receipt Started, Products Added, Organization
    const [receiptStartedState, setReceiptStartedState] = useState(false);
    const [productsAddedState, setProductsAddedState] = useState(false);
    const [orgState, setOrgState] = useState({
        id: '',
        name: ''
    });
    // State: Started Messaging, Product Messaging
    const [startedMessageState, setStartedMessageState] = useState({
        error: false,
        message: '',
    });
    const [productMessageState, setProductMessageState] = useState({
        error: false,
        message: '',
    });
    const [defaultReceiptQueryString] = useState(assembleDefaultQueryString('Receipt'));
    // State: Receipt Complete, Error, Messaging
    const [receiptState, setReceiptState] = useState({
        complete: false,
        error: false,
        message: '',
        submitting: false
    });
    // State: Tab display
    const [tabState, setTab] = useState(0);
    const handleTabChange = (e, newValue) => setTab(newValue);
    // State: Create Product Dialog
    const [createProductDialogOpen, setCreateProductDialogOpen] = useState(false);
    // State: Product Qty update
    const [productToUpdate, setProductToUpdate] = useState(null);
    const [productsSubmitted, setProductsSubmitted] = useState(false);
    const [refreshProducts, setRefreshProducts] = useState(false);
    // State: Stored Receipts
    const [refreshStoredReceipts, setRefreshStoredReceipts] = useState(false);

    const handleResumeStoredReceipt = (e, form) => {
        const receipt = getStoredOrderById('receipt', e.id);
        if (receipt) {
            receipt.org_name = e.name;
            handleSetValuesFromStored(receipt, form);
            setStoredReceiptRefresh();
        }
    };

    const saveReceiptToStored = (data) => {
        updateStoredOrders('receipt', data);
        setStoredReceiptRefresh();
    };

    const setStoredReceiptRefresh = () => {
        setRefreshStoredReceipts(true);
        setTimeout(function () {
            setRefreshStoredReceipts(false);
        }, 100);
    };

    /**
     * Process updates from Form Tab buttons
     *
     * @param form
     * @param step
     * @param values
     */
    const handleFormStep = (form, step, values) => {
        let data;
        let receiptStarted = receiptStartedState;

        // Step: Initialize a Receipt to retrieve an ID
        if (step === 'startReceipt') {

            data = assembleFormData(step, values);

            postReceipt('startReceipt', data).then(result => {
                // Store the resulting ID; set Form stage; advance to Add Products Tab
                form.mutators.setValue('id', result.id);
                setReceiptStartedState(true);
                setStartedMessageState({error: false, message: ''});
                setTab(1);
                // Store Receipt in case it cannot be completed
                saveReceiptToStored(form.getState().values);
            }).catch(error => {
                form.mutators.setValue('id', null);
                setReceiptStartedState(false);
                setProductsAddedState(false);
                setStartedMessageState({
                    error: true,
                    message: 'Sorry, there was an error creating this Receipt. Please check and resubmit.'
                });
                setTab(0);
            });
        }

        // Step: Go to the Add/Update Products tab
        if (step === 'goToAddProducts') {
            setReceiptStartedState(true);
            setTab(1);
        }

        // Step: Go to the Create Receipt tab
        if (step === 'goToCreateReceipt') {
            // Products have been added; set Form stage; advance to Create (Complete) Receipt Tab
            setReceiptStartedState(receiptStarted);
            setProductsAddedState(true);
            setTab(2);
        }

        // Step: Submit all Products and create/finalize the Receipt
        if (step === 'createReceipt') {

            // Assemble form data for Receipt call
            data = assembleFormData(step, values);
            let items = JSON.parse(JSON.stringify(values.items));
            setReceiptState({
                complete: false,
                error: false,
                message: 'Submitting Receipt...',
                submitting: true
            });

            // Post Receipt function
            const postReceiptData = (data) => {
                postReceipt('createReceipt', data).then(result => {
                    // Receipt has been completed
                    setReceiptState({
                        complete: true,
                        error: false,
                        message: 'Your Receipt has been saved.',
                        submitting: false
                    })
                    handleFormStep(form, 'goToReceiptCompleted', values);
                    // Remove completed Receipt from stored Receipts
                    removeStoredOrder('receipt', values.id);
                    setStoredReceiptRefresh();

                }).catch(error => {
                    console.log('receipt error ', error);
                    setReceiptStartedState(true);
                    setProductsAddedState(true);
                    setReceiptState({
                        complete: false,
                        error: true,
                        message: 'Sorry, there was an error saving this Receipt.',
                        submitting: false
                    });
                });
            };

            // Sequentially post all Products in list if they have not already been submitted
            let tasks = [];
            let taskLength = 0;
            for (let i = 0; i < items.length; i++) {
                const task = assembleProductSubmitData(items[i], values);
                const taskIdx = tasks.findIndex(r => r.product_id === task.product_id);
                if (!task.submitted && taskIdx === -1) {
                    tasks.push({
                        index: taskLength,
                        data: task
                    });
                    taskLength++;
                }
            }

            if (productsSubmitted) {
                // If Receipt is being submitted again due to an error, do not resubmit Products
                postReceiptData(data);
            } else {
                // Start a Promise sequence with all tasks
                const starterPromise = Promise.resolve(null);
                tasks.reduce(
                    (p, spec) => p.then(() => postItem('addItem', spec.data)
                        .then((result) => {
                            // Update this Product as submitted
                            const itemsIdx = items.findIndex(r => r.product_id === result.product_id);
                            if (itemsIdx !== -1) {
                                items[itemsIdx].submitted = true;
                            }

                            // If all tasks in the list have completed:
                            if (spec.index >= (taskLength - 1)) {
                                // Update Product list with submitted states
                                form.mutators.setValue('items', items);
                                setProductsSubmitted(true);

                                // Products have been submitted; post Receipt
                                postReceiptData(data);
                            }
                        })
                    ), starterPromise)
                    .catch((error) => {
                            setReceiptState({
                                complete: false,
                                error: true,
                                message: 'Sorry, there was an error saving Products for this Receipt.',
                                submitting: false
                            });
                            // Update Product items with submitted states
                            form.mutators.setValue('items', items);
                            setProductsSubmitted(false);
                            setTab(1);
                        }
                    );
            }
        }

        // Step: Go to the Completed Receipt tab
        if (step === 'goToReceiptCompleted') {
            setReceiptStartedState(true);
            setProductsAddedState(true);
            setTab(3);
        }
    };

    // Store Org ID for Dialog
    const handleSetOrg = (e, form) => {
        setOrgState({id: e.id, name: e.name});
        form.mutators.setValue('org_id', e?.id ?? '');

        // Update stored Receipt after selection
        saveReceiptToStored(form.getState().values);
    };

    // Update stored Receipt values from text field updates
    const handleSetValueFromField = (e, field, form) => {
        saveReceiptToStored(form.getState().values);
    };

    // Populate form with selected stored Order values
    const handleSetValuesFromStored = (order, form) => {
        setOrgState({id: order.org_id, name: order.org_name});
        form.mutators.setValue('id', order.id);
        form.mutators.setValue('org_id', order?.org_id ?? '');
        form.mutators.setValue('org_name', order?.org_name ?? '');

        form.mutators.setValue('tracking_number', order?.tracking_number ?? '');
        form.mutators.setValue('notes', order?.notes ?? '');

        // Clear any items previously added
        form.mutators.setValue('items', []);
        // Add items from stored Receipt
        if (order.items) {
            form.mutators.setValue('items', order.items);
            setProductsAddedState(true);
        }

        // Set form state and tab
        setReceiptStartedState(true);
        setStartedMessageState({error: false, message: ''});
        setTab(1);
    };

    /**
     * Assemble data for Receipt POST calls based on form values
     *
     * @param step
     * @param values
     */
    const assembleFormData = (step, values) => {
        let data = {};
        if (step === 'startReceipt' || step === 'createReceipt' || step === 'updateReceipt') {
            data.org_id = values.org_id;
        }
        if (step === 'createReceipt' || step === 'updateReceipt') {
            data.id = values.id;
        }
        if (step === 'createReceipt') {
            data.notes = values?.notes ?? '';
            data.tracking_number = values?.tracking_number ?? '';
        }
        return data;
    };

    /**
     * Handle event to add or remove a Product or adjust its requested Qty
     *
     * @param e
     * @param form
     * @param values
     */
    const handleAddOrRemoveItem = (e, form, values) => {
        const item = JSON.parse(JSON.stringify(e.item));
        const items = (values.items) ? JSON.parse(JSON.stringify(values.items)) : [];
        let idx = items.findIndex(r => r.product_id === item.product_id);
        let removeItem = false;
        let error = false;
        let message = '';
        let listUpdated = false;

        // If requested to add:
        if (e.add) {
            if (idx === -1) {
                // If not in the list, add the Receipt's ID, add the Product, and update the form
                item.receipt_id = values.id;
                item.qty = item.qty_requested;
                items.unshift(item);
                idx = items.findIndex(r => r.product_id === item.product_id);
                listUpdated = true;
                setProductsAddedState(true);
            } else {
                if (item.qty_requested !== items[idx].qty || item.num_boxes !== items[idx].num_boxes) {
                    // Item is in list; update requested values
                    items[idx].qty = item.qty_requested;
                    items[idx].num_boxes = item.num_boxes;
                    listUpdated = true;

                    // If Qty has become 0, consider the item removed
                    if (items[idx].qty === 0) {
                        removeItem = true;
                    }
                }
            }
        }

        // Update Available table if tally table was edited:
        if (e.updateAvailable && items[idx] !== -1) {
            items[idx].qty_requested = item.qty_requested;
            items[idx].num_boxes = item.num_boxes;
            items[idx].added = item.added;
            items[idx].notes = item.notes;
            setProductToUpdate(items[idx]);
            setTimeout(function () {
                setProductToUpdate(null);
            }, 100);
        }

        // Refresh Available table upon new Product:
        if (e.refreshAvailable) {
            setRefreshProducts(true);
            setTimeout(function () {
                setRefreshProducts(false);
                if (item) {
                    setTimeout(function () {
                        handleAddOrRemoveItem({
                            item: item,
                            add: true,
                            updateAvailable: true,
                            refreshAvailable: false
                        }, form, values);
                    }, 800);
                }
            }, 100);
        }

        // If requested to remove:
        if (!e.add && idx !== -1) {
            removeItem = true;
        }

        // If item is requested to be removed or its Qty is 0, remove it from the list
        if (removeItem) {
            items[idx].added = false;
            items.splice(idx, 1);
            listUpdated = true;
        }

        // Update form values
        if (listUpdated) {
            form.mutators.setValue('items', items);
            setProductsAddedState(true);
            // Update stored Receipt with item updates
            saveReceiptToStored(form.getState().values);
        }

        // Update message state
        setProductMessageState({error: error, message: message});

        if (items.length === 0) {
            setProductsAddedState(false);
        }
    };

    const handleEditProductName = (product, form, values) => {
        // Update any added Products
        const productId = product.product_id ?? product.id;
        const formState = (form) ? form.getState() : null;
        const items = formState?.values?.items ?? [];
        const addedIdx = items.findIndex(r => r.product_id === productId);
        let add = false;
        if (addedIdx !== -1) {
            items[addedIdx].title = product.title;
            form.mutators.setValue('items', items);
            add = true;
        }
        // Refresh Available Products with re-add if applicable
        setRefreshProducts(true);
        setTimeout(function () {
            setRefreshProducts(false);
            if (add) {
                setTimeout(function () {
                    handleAddOrRemoveItem({
                        item: items[addedIdx],
                        add: add,
                        updateAvailable: true,
                        refreshAvailable: false
                    }, form, formState.values);
                }, 800);
            }
        }, 100);
    };

    const handleSetItemNotes = (e, form, values) => {
        const item = JSON.parse(JSON.stringify(e.item));
        const items = (values.items) ? JSON.parse(JSON.stringify(values.items)) : [];
        const idx = items.findIndex(r => r.product_id === item.product_id);
        if (idx !== -1) {
            items[idx].notes = item.notes;
            form.mutators.setValue('items', items);
            setProductToUpdate(items[idx]);
            setTimeout(function () {
                setProductToUpdate(null);
            }, 100);
            // Update stored Receipt with item updates
            saveReceiptToStored(form.getState().values);
        }
    };

    const assembleProductSubmitData = (item, values) => {
        let data = {};
        data.approved = false;
        data.receipt_id = values.id;
        data.product_id = item.product_id;
        data.qty = item.qty;
        data.num_boxes = item.num_boxes;
        data.notes = item.notes;
        return data;
    };

    const handleSubmit = (e) => {
        // Form is not submitted conventionally
        e.preventDefault();
    };

    const handleProductCreated = (product, form, values) => {
        // Automatically add new Product to Added Products
        const item = {
            ...product,
            added: true,
            num_boxes: product.num_boxes,
            qty_requested: 1,
            product_id: product.id,
            notes: '',
            submitted: false
        };
        handleAddOrRemoveItem({item: item, add: true, refreshAvailable: true}, form, values);
    };

    const handleCloseCreateProductDialog = () => {
        setCreateProductDialogOpen(false);
    };

    const classes = useStyles();

    return (
        <>
            <CreateTitle/>
            <CreateToolbar {...props}/>
            <Form
                onSubmit={handleSubmit}
                mutators={{
                    setValue: ([field, value], state, {changeValue}) => {
                        changeValue(state, field, () => value)
                    }
                }}
                render={({form, handleSubmit, values}) => {

                    // Determine disabled status for buttons and tabs
                    const isDisabled = (item) => {
                        const v = values;
                        switch (item) {
                            case 'submit0':
                                return (!v.org_id);
                            case 'submit1':
                                return (!productsAddedState);
                            case 'submit2':
                                return (receiptState.complete || receiptState.submitting);
                            case 'tab0':
                                return (receiptStartedState);
                            case 'tab1':
                                return (!receiptStartedState);
                            case 'tab2':
                                return (!productsAddedState || !receiptStartedState);
                            case 'tab3':
                                return (!receiptState.complete);
                            default:
                                return false;
                        }
                    };

                    return (
                        <form onSubmit={handleSubmit}>
                            <div className={classes.root}>

                                {/* Tab Bar */}

                                <AppBar position="relative" className={classes.appBar}>
                                    <Tabs
                                        value={tabState}
                                        onChange={handleTabChange}
                                        aria-label="Receipt Form Tabs"
                                        classes={{indicator: classes.tabIndicator}}
                                    >
                                        <Tab id="receipt-tab-start" label="Start Receipt"
                                             aria-controls="receipt-tabpanel-start" disabled={isDisabled('tab0')}/>
                                        <Tab id="receipt-tab-add" label="Add Products"
                                             aria-controls="receipt-tabpanel-add" disabled={isDisabled('tab1')}/>
                                        <Tab id="receipt-tab-complete" label="Complete Receipt"
                                             aria-controls="receipt-tabpanel-complete" disabled={isDisabled('tab2')}/>
                                        <Tab id="receipt-tab-completed" label="Receipt Completed"
                                             aria-controls="receipt-tabpanel-completed" disabled={isDisabled('tab3')}/>
                                    </Tabs>
                                </AppBar>

                                {/* Tab Content: Start Receipt */}

                                <div
                                    role="tabpanel"
                                    hidden={tabState !== 0}
                                    id="receipt-tabpanel-start"
                                    aria-labelledby="receipt-tab-start"
                                    className="tab-panel form-receipt"
                                >
                                    <FieldDescription
                                        instructions={true} marginBottom={true}
                                        text={`If you have previously begun a Receipt, you may resume creating 
                                        it, delete it, or start a new Receipt.`}
                                    />
                                    <StoredOrdersReceipts
                                        type="receipt"
                                        onResume={(e) => handleResumeStoredReceipt(e, form)}
                                        onDelete={() => setStoredReceiptRefresh()}
                                        refresh={refreshStoredReceipts}
                                    />
                                    <FieldDivider type="break" margin="bottom"/>
                                    <FieldDivider type="break" margin="bottom"/>

                                    <FieldDescription
                                        instructions={true} marginBottom={true}
                                        text={(!receiptStartedState)
                                            ? `Start a Receipt by selecting a Client.`
                                            : `To select a different Client, please create a different Receipt.`
                                        }
                                    />

                                    <Field name="OrgField">
                                        {props => (
                                            <SelectFromListInput
                                                source="org_id_select"
                                                displayField={(tabState === 0)}
                                                resourceName="Organization"
                                                listLabel="Select a Client"
                                                itemPlural="Clients"
                                                listFilters={{
                                                    active: true
                                                }}
                                                className="input-block input-400"
                                                selectedObject={(orgState && orgState.id !== '') ? {
                                                    id: orgState.id,
                                                    name: orgState.name
                                                } : {id: '', name: ''}}
                                                isRequired={true}
                                                hasClear={false}
                                                onChangeFunc={(e) => handleSetOrg(e, form)}
                                            />
                                        )}
                                    </Field>
                                    <TextInput label="" source="org_id" className="hidden" disabled/>

                                    {startedMessageState.message &&
                                        <FieldMessage
                                            error={(startedMessageState.error)}
                                            text={startedMessageState.message}
                                        />
                                    }

                                    <FieldDescription
                                        instructions={true} marginBottom={true}
                                        text={`Once Client is set, click Start Receipt. You will then be 
                                        able to add Products to the Receipt.`}
                                    />
                                    <FieldDescription
                                        instructions={true} marginBottom={true}
                                        text={`If new Products are received that do not yet exist, they may be 
                                        created in the Add Products tab.`}
                                    />
                                    <div className="btns-container right">
                                        {!receiptStartedState &&
                                            <Button
                                                color="primary"
                                                variant="contained"
                                                onClick={() => handleFormStep(form, 'startReceipt', values)}
                                                disabled={!!(isDisabled('submit0'))}
                                            >
                                                Start Receipt
                                            </Button>
                                        }
                                        {receiptStartedState &&
                                            <Button
                                                color="primary"
                                                variant="contained"
                                                onClick={() => handleFormStep(form, 'updateReceipt', values)}
                                                disabled={!!(isDisabled('submit0'))}
                                            >
                                                Update Receipt
                                            </Button>
                                        }
                                    </div>
                                </div>

                                {/* Tab Content: Add Product Items */}

                                <div
                                    role="tabpanel"
                                    hidden={tabState !== 1}
                                    id="receipt-tabpanel-add"
                                    aria-labelledby="receipt-tab-add"
                                    className="tab-panel form-receipt"
                                >
                                    <FieldDescription
                                        instructions={true} marginBottom={true}
                                        text={`Choose Products to add to this Receipt.`}
                                    />
                                    <FieldDescription
                                        marginBottom={true}
                                        text={`Filter or search to view available Products.`}
                                    />
                                    <FieldDescription
                                        marginBottom={false}
                                        text={`• For each Product to add, click its Add icon. Enter the received 
                                        quantity, the number of boxes containing the Product, and check the 
                                        Add checkbox.`}
                                    />
                                    <FieldDescription
                                        marginBottom={true}
                                        text={`• To remove a Product from the Items Added table, click its Remove icon.`}
                                    />
                                    <FieldDescription
                                        marginBottom={true}
                                        text={`• To add a new Product, click CREATE NEW PRODUCT. Once created it will 
                                        be added to the Items Added table.`}
                                    />

                                    <Button
                                        variant="contained"
                                        color="default"
                                        onClick={() => setCreateProductDialogOpen(true)}
                                        className={classes.addNewButton}
                                    >
                                        Create New Product
                                    </Button>
                                    <br/>

                                    <ReceiptItemsAvailableListContainer
                                        {...props}
                                        type="create"
                                        displayFields={(tabState === 1)}
                                        orgId={values.org_id}
                                        productToUpdate={productToUpdate}
                                        refreshList={refreshProducts}
                                        addedList={values.items}
                                        onProductAdjust={(e) => handleAddOrRemoveItem(e, form, values)}
                                        onProductNameEdit={(e) => handleEditProductName(e, form, values)}
                                    />
                                    {productMessageState.message &&
                                        <FieldMessage
                                            error={(productMessageState.error)}
                                            text={productMessageState.message}
                                        />
                                    }
                                    <FieldDivider type="divider" margin="bottom"/>
                                    <FieldDescription text={`Items Added`} instructions={true}/>
                                    {(!values.items || values?.items?.length === 0) &&
                                        <FieldDescription
                                            marginBottom={true}
                                            text={`No Products have yet been added to this Receipt.`}
                                        />
                                    }
                                    {values?.items?.length > 0 &&
                                        <FieldDescription
                                            marginBottom={true}
                                            text={`The following Products will be added to this Receipt. You may adjust or remove Products after adding, or add notes for each:`}
                                        />
                                    }
                                    <ReceiptItemsList
                                        listType="create"
                                        itemList={values.items}
                                        hasEditName={true}
                                        hasEditQty={true}
                                        hasEditNotes={true}
                                        hasDelete={false}
                                        hasRemove={true}
                                        onEditQtyFunc={(e) => handleAddOrRemoveItem(e, form, values)}
                                        onEditNameFunc={(e) => handleEditProductName(e, form, values)}
                                        onEditNotesFunc={(e) => handleSetItemNotes(e, form, values)}
                                        onRemoveFunc={(e) => handleAddOrRemoveItem(e, form, values)}
                                    />
                                    <FieldDivider type="divider" margin="bottom"/>

                                    <FieldDescription
                                        instructions={true}
                                        text={`When finished adding Products, click Continue and 
                                        complete the Receipt.`}
                                    />
                                    <FieldDescription
                                        instructions={true}
                                        text={`Note: Do not click the Back button in your browser.`}
                                    />
                                    <div className="btns-container right">
                                        <Button
                                            color="primary"
                                            variant="contained"
                                            onClick={() => handleFormStep(form, 'goToCreateReceipt', values)}
                                            disabled={!!(isDisabled('submit1'))}
                                        >
                                            Continue
                                        </Button>
                                    </div>
                                </div>

                                {/* Tab Content: Complete Receipt */}

                                <div
                                    role="tabpanel"
                                    hidden={tabState !== 2}
                                    id="receipt-tabpanel-complete"
                                    aria-labelledby="receipt-tab-complete"
                                    className="tab-panel form-receipt"
                                >
                                    <FieldDescription
                                        instructions={true} marginBottom={true}
                                        text={`Add a Tracking Number for this Receipt. If there are 
                                        additional notes or instructions, add them here. Admin Notes 
                                        are accessible by Parrot admins only.`}
                                    />

                                    <TextInput label="Tracking Number" source="tracking_number" fullWidth={true}
                                               onBlur={(e) => handleSetValueFromField(e, 'tracking_number', form)}
                                    />

                                    <TextInput label="Admin Notes" source="notes" multiline fullWidth={true}
                                               inputProps={{
                                                   minRows: 3
                                               }}
                                               onBlur={(e) => handleSetValueFromField(e, 'notes', form)}
                                    />

                                    {receiptState.message &&
                                        <FieldMessage error={(receiptState.error)} text={receiptState.message}/>
                                    }

                                    <FieldDescription
                                        instructions={true}
                                        text={`Click Complete Receipt to submit the Receipt and its Items.`}
                                    />
                                    <FieldDescription
                                        instructions={true}
                                        text={`Note: Do not click the Back button in your browser.`}
                                    />
                                    <div className="btns-container right">
                                        <Button
                                            color="primary" variant="outlined"
                                            onClick={() => handleFormStep(form, 'goToAddProducts', values)}
                                        >
                                            Back
                                        </Button>
                                        <Button
                                            color="primary"
                                            variant="contained"
                                            onClick={() => handleFormStep(form, 'createReceipt', values)}
                                            disabled={!!(isDisabled('submit2'))}
                                        >
                                            Complete Receipt
                                        </Button>
                                    </div>
                                </div>

                                {/* Tab Content: Receipt Completed */}

                                <div
                                    role="tabpanel"
                                    hidden={tabState !== 3}
                                    id="receipt-tabpanel-completed"
                                    aria-labelledby="receipt-tab-completed"
                                    className="tab-panel form-receipt"
                                >
                                    <FieldDescription
                                        instructions={true} marginBottom={true}
                                        text={`This Receipt has been submitted. You may edit this Receipt's notes.`}
                                    />
                                    {receiptState.message &&
                                        <FieldMessage text={receiptState.message}/>
                                    }
                                    <Button
                                        variant="outlined"
                                        component={Link}
                                        to={`/Receipt${defaultReceiptQueryString}`}
                                    >
                                        View Receipts List
                                    </Button>
                                </div>
                            </div>
                            <ProductCreateDialog
                                forReceipt={true}
                                selectedOrg={orgState}
                                selectedOrgId={orgState.id}
                                openDialog={createProductDialogOpen}
                                onCompleteFunc={(e) => handleProductCreated(e, form, values)}
                                onCloseFunc={handleCloseCreateProductDialog}
                            />
                        </form>
                    )
                }}
            >
            </Form>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        keycloakAuthenticated: state.keycloakState.keycloakAuthenticated,
        keycloakReady: state.keycloakState.keycloakReady,
        tokens: {
            token: state.tokens.token
        }
    };
};

export default withRouter(connect(
        mapStateToProps,
        {}
    )(ReceiptCreateForm)
);
