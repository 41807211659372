import axios from 'axios';
import {getPostHeaders} from './headers';
import {RESOURCES as resources} from '../receiptResources';

/**
 * Post a request to Start, Create or Update a Receipt
 *
 * @param type
 * @param data
 * @return {Promise<unknown>}
 */
export const postReceipt = (type, data) => {
    return new Promise(function (resolve, reject) {
        if (data) {

            const onFailure = function (result) {
                return reject('Receipt failed: ', result);
            }

            const onSuccess = function (result) {
                return resolve(result);
            }

            const params = {
                data: data
            };
            let cfg;
            switch (type) {
                case 'startReceipt':
                    cfg = resources.ReceiptStart.CREATE({id: data.org_id});
                    break;
                case 'createReceipt':
                    cfg = resources.ReceiptCreate.CREATE(params);
                    break;
                case 'updateReceipt':
                    cfg = resources.Receipt.UPDATE(params);
                    break;
                default:
                    break;
            }

            // Set headers
            const headers = getPostHeaders(true);

            try {
                axios({
                    method: cfg.method,
                    url: cfg.uri,
                    data: cfg.data,
                    headers: headers
                })
                    .then((result) => {
                        if (result && result.data) {
                            return onSuccess(result.data);
                        }
                    })
                    .catch((error) => {
                        if (error.response) {
                            // Server response outside 2xx
                            console.log('Receipt Error response: ', error);
                            onFailure(error.response);
                        } else if (error.request) {
                            // No response
                            console.log('Receipt Error request: ', error);
                            onFailure(error.request);
                        } else {
                            console.log('Receipt Error: ', error);
                            onFailure(error);
                        }
                    });
            } catch (err) {
                console.log('Receipt try/catch error: ', err);
                onFailure(err);
            }
        } else {
            return resolve();
        }
    });
};

/**
 * Post a request to Add, Update or Delete a Product in a Receipt
 *
 * @param type
 * @param data
 * @return {Promise<unknown>}
 */
export const postItem = (type, data) => {
    return new Promise(function (resolve, reject) {
        if (data) {

            const onFailure = function (result) {
                return reject('Receipt Item failed: ', result);
            }

            const onSuccess = function (result) {
                return resolve(result);
            }

            let productToPost = {
                receipt_id: data.receipt_id,
                product_id: data.product_id,
                qty: data.qty,
                num_boxes: data.num_boxes,
                notes: data.notes
            };
            if (type === 'updateItem') {
                productToPost.id = data.id;
                productToPost.new_qty = data.new_qty;
            }
            if (type === 'updateNotes') {
                productToPost.id = data.id;
            }
            if (type === 'deleteItem') {
                productToPost.id = data.id;
            }
            const params = {
                data: productToPost
            };
            let cfg;
            switch (type) {
                case 'addItem':
                    cfg = resources.ReceiptItem.CREATE(params);
                    break;
                case 'updateItem':
                    cfg = resources.ReceiptItem.UPDATE(params);
                    break;
                case 'updateNotes':
                    cfg = resources.ReceiptItemNotes.UPDATE(params);
                    break;
                case 'deleteItem':
                    cfg = resources.ReceiptItem.DELETE(params);
                    break;
                default:
                    break;
            }

            // Set headers
            const headers = getPostHeaders(true);

            try {
                axios({
                    method: cfg.method,
                    url: cfg.uri,
                    data: cfg.data,
                    headers: headers
                })
                    .then((result) => {
                        if (result && result.data) {
                            return onSuccess(result.data);
                        }
                    })
                    .catch((error) => {
                        if (error.response) {
                            // Server response outside 2xx
                            console.log('Receipt Item Error response: ', error);
                            onFailure(error.response);
                        } else if (error.request) {
                            // No response
                            console.log('Receipt Item Error request: ', error);
                            onFailure(error.request);
                        } else {
                            console.log('Receipt Item Error: ', error);
                            onFailure(error);
                        }
                    });
            } catch (err) {
                console.log('Receipt Item try/catch error: ', err);
                onFailure(err);
            }
        } else {
            return resolve();
        }
    });
};
