import React, {useEffect, useState} from 'react';
import {Form} from 'react-final-form';
import axios from 'axios';
import {
    TextInput,
    required
} from 'react-admin';
import {RESOURCES as resources} from '../productResources';
import {getGetHeaders, getPostHeaders} from '../utils/headers';
import {postProductImage} from '../utils/apiFunctions';
import FieldDescription from './FieldDescription';
import FieldDivider from './FieldDivider';
import ProductImage from '../components/ProductImage';
import ProductLocationsInput from './ProductLocationsInput';
import SelectFromListInput from './SelectFromListInput';
import {useDialogStyles, useLoadingStyles} from '../global/dialogStyles';
import {viewStyles} from '../global/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

const validateName = [required()];

/**
 * A Material UI Dialog to display a Product Edit form
 *
 * @param openDialog
 * @param onCloseFunc
 * @param onCompleteFunc
 * @param productId
 * @param selectedOrg
 * @param selectedOrgId
 * @param success
 * @param forReceipt
 * @returns {JSX.Element}
 * @constructor
 */
const ProductEditDialog = (
    {
        openDialog,
        onCloseFunc,
        onCompleteFunc,
        productId,
        selectedOrg,
        selectedOrgId,
        success,
        forReceipt = true
    }) => {

    const [open, setOpen] = useState(false);
    const [record, setRecord] = useState(null);
    const [submitting, setSubmitting] = useState(false);
    const [formMessage, setFormMessage] = useState({
        error: false,
        message: null
    });

    useEffect(
        () => {
            const fetchItem = () => {
                const cfg = resources.Product.GET_ONE({id: productId});
                axios({
                    method: 'GET',
                    url: cfg.uri,
                    headers: getGetHeaders()
                }).then((response) => {
                    if (response.data) {
                        let type_display_name = response.data?.type_name ?? '';
                        if (response.data.parent_type_name && response.data.type_name) {
                            type_display_name = response.data.parent_type_name + ' > ' + type_display_name;
                        }
                        response.data.type_display_name = type_display_name;
                        setRecord(response.data);
                        setOpen(true);
                    } else {
                        setRecord(null);
                    }
                }).catch((error) => {
                    console.log('error response for Product: ', error);
                    if (error.response) {
                        // Server response outside 2xx
                    } else if (error.request) {
                        // No response
                    }
                });
            };

            // If current Item ID is set or has changed, fetch that Product
            if (
                (productId && !record) ||
                (productId && record && (parseInt(record.id, 10) !== parseInt(productId, 10)))
            ) {
                fetchItem();
            }

        }, [productId, record]
    );

    // Effect: Open dialog from parent
    useEffect(() => {
        if (openDialog) {
            setFormMessage({
                error: false,
                message: null
            });
            setRecord(null);
        }
        return setOpen(openDialog);
    }, [openDialog]);

    const validateForm = (values) => {
        return !(
            !values.show_id ||
            !values.type_id ||
            !values.title ||
            (values.new_product_org_id && (values.new_product_org_id !== values.org_id) && !values.new_product_show_id)
        );
    };

    const assembleFormData = (values) => {
        const v = values;
        return {
            description: (v.new_product_description) ? v.new_product_description : v.description,
            notes: (v.new_product_notes) ? v.new_product_notes : v.notes,
            org_id: (v.new_product_org_id) ? parseInt(v.new_product_org_id, 10) : v.org_id,
            show_id: (v.new_product_show_id) ? parseInt(v.new_product_show_id, 10) : v.show_id,
            title: (v.new_product_title) ? v.new_product_title : v.title,
            type_id: (v.new_product_type_id) ? parseInt(v.new_product_type_id, 10) : v.type_id,
            active: record.active,
            id: record.id,
            qty: record.qty,
            sku: record.sku,
            thumbnail: record.thumbnail,
            warehouse_locations: record.warehouse_locations
        };
    };

    const handleSubmit = (values) => {
        const formValues = assembleFormData(values);
        const imageInput = values.product_image_input ?? null;
        let errorMessage = 'Sorry, there was an error creating this Product.';
        const resource = resources.Product.UPDATE(formValues);
        setSubmitting(true);
        axios({
            method: resource.method,
            url: resource.uri,
            data: formValues,
            headers: getPostHeaders(true)
        }).then((response) => {
            if (response.data && response.data.id) {
                setSubmitting(false);
                // Post image if uploaded
                if (imageInput && response.data.id) {
                    setFormMessage({
                        error: false,
                        message: 'Product saved, uploading image...'
                    });
                    submitProductImage(values, response.data, imageInput);
                } else if (forReceipt) {
                    // Get new Product with all data
                    setFormMessage({
                        error: false,
                        message: 'Product saved. Updating Receipt...'
                    });
                    getNewProduct(response.data.id, values);
                } else {
                    // Complete creation
                    setFormMessage({
                        error: false,
                        message: 'Product saved.'
                    });
                    completeProduct(response.data);
                }
            } else {
                setSubmitting(false);
                setFormMessage({
                    error: true,
                    message: errorMessage
                });
            }
        }).catch((error) => {
            if (error.response) {
                // Server response outside 2xx
            } else if (error.request) {
                // No response
            }
            setSubmitting(false);
            setFormMessage({
                error: true,
                message: errorMessage
            });
        });
    };

    const onSaveProgress = (e) => {
    };

    const submitProductImage = (values, data, imageInput) => {
        if (imageInput && data.id) {
            data.product_image_input = imageInput;
            postProductImage(data, onSaveProgress).then(result => {
                if (forReceipt) {
                    // Get new Product with all data
                    setFormMessage({
                        error: false,
                        message: 'Product image uploaded. Updating Receipt...'
                    });
                    getNewProduct(data.id, values);
                } else {
                    // Complete creation
                    setFormMessage({
                        error: false,
                        message: 'Product saved.'
                    });
                    completeProduct(data);
                }
            }).catch(error => {
                setFormMessage({
                    error: true,
                    message: 'Sorry, there was an error uploading this Product\'s image.'
                });
            });
        }
    };

    const getNewProduct = (id, values) => {
        const cfg = resources.Product.GET_ONE({id: id});
        axios({
            method: 'GET',
            url: cfg.uri,
            headers: getGetHeaders()
        }).then((response) => {
            if (response.data && response.data.id) {
                if (forReceipt) {
                    response.data.num_boxes = (values.new_product_boxes) ? values.new_product_boxes : 0;
                    response.data.product_id = response.data.id;
                }
                completeProduct(response.data);
            }
        }).catch((error) => {
            console.log('error response for Product: ', error);
            if (error.response) {
                // Server response outside 2xx
            } else if (error.request) {
                // No response
            }
            completeProduct(null);
        });
    };

    const completeProduct = (data) => {
        if (!data) {
            setFormMessage({
                error: true,
                message: 'Sorry, there was an error adding this Product.'
            });
        } else {
            setFormMessage({
                error: false,
                message: 'Product saved.'
            });
        }
        setTimeout(() => {
            if (typeof onCompleteFunc === 'function') {
                onCompleteFunc(data);
            }
            handleClose(null);
        }, 1000);
    };

    const handleClose = () => {
        setOpen(false);
        if (typeof onCloseFunc === 'function') {
            onCloseFunc();
        }
    };

    const setShowSelect = (values) => {
        let newOrgId = (values.new_product_org_id && values.new_product_org_id !== values.org_id);
        let desc = (newOrgId)
            ? 'Select a new Title from the new Client.'
            : 'If this Product should be associated with a different Title, select a new Title. Otherwise, leave blank.';
        let label = (newOrgId)
            ? 'Select New Title (required)'
            : 'Change Title (optional)';
        let isRequired = !!(newOrgId);
        let error = !!(newOrgId && !values.new_product_show_id);
        let errorText = (newOrgId && !values.new_product_show_id)
            ? 'Please select a Title from the new Client.'
            : ''
        return {
            desc: desc,
            label: label,
            isRequired: isRequired,
            error: error,
            errorText: errorText
        }
    };

    const classes = useDialogStyles();
    const loadingClasses = useLoadingStyles();

    return (
        <>
            <Dialog
                fullWidth
                maxWidth={false}
                onClose={handleClose}
                aria-labelledby="Product-Edit"
                open={open}
                className={classes.root}
                transitionDuration={{
                    enter: 100,
                    exit: 150
                }}
            >
                <MuiDialogTitle disableTypography className={classes.title}>
                    <Typography variant="h6">
                        Edit Product
                    </Typography>
                    <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
                        <CloseIcon/>
                    </IconButton>
                </MuiDialogTitle>
                <MuiDialogContent dividers>
                    {!record &&
                        <CircularProgress size={30} className={loadingClasses.root}/>
                    }
                    {record && record.id &&
                        <Form
                            onSubmit={handleSubmit}
                            initialValues={record}
                            mutators={{
                                setValue: ([field, value], state, {changeValue}) => {
                                    changeValue(state, field, () => value)
                                }
                            }}
                            render={({form, handleSubmit, values}) => {
                                return (
                                    <form onSubmit={handleSubmit}>
                                        <FieldDescription
                                            instructions={true} marginBottom={true}
                                            text="Update Product detail below."
                                        />
                                        <FieldDescription header={true} margin="bottom" text="Client and Title"/>
                                        <TextInput disabled label="Client" source="org_name"
                                                   className="input-inline input-320"/>
                                        <TextInput disabled label="Title" source="show_name"
                                                   className="input-inline input-320"/>
                                        <FieldDivider type="break"/>

                                        <FieldDescription
                                            description={true}
                                            text={`If this Product should be associated with a different Client, 
                                            select a new Client. Otherwise, leave blank. If you select a new Client, 
                                            you must select a new Title.`}
                                        />
                                        <FieldDivider type="break"/>

                                        <SelectFromListInput
                                            inAdminForm={true}
                                            source="new_product_org_id"
                                            resourceName="Organization"
                                            listLabel="Change Client (optional)"
                                            itemPlural="Clients"
                                            listFilters={{
                                                active: true
                                            }}
                                            className="input-block input-full"
                                            isRequired={false}
                                            returnType="string"
                                        />

                                        <FieldDescription
                                            description={true}
                                            text={setShowSelect(values).desc}
                                        />
                                        <FieldDivider type="break"/>
                                        <SelectFromListInput
                                            inAdminForm={true}
                                            source="new_product_show_id"
                                            resourceName="ShowByOrg"
                                            listLabel={setShowSelect(values).label}
                                            itemPlural="Titles"
                                            listFilters={{
                                                active: true,
                                                org_id: (values.new_product_org_id) ? values.new_product_org_id : values.org_id
                                            }}
                                            className="input-full error"
                                            isRequired={setShowSelect(values).isRequired}
                                            error={setShowSelect(values).error}
                                            errorText={setShowSelect(values).errorText}
                                            returnType="string"
                                        />
                                        <FieldDivider type="break" margin="bottom"/>

                                        <FieldDescription header={true} margin="bottom" text="Category"/>
                                        <FieldDescription
                                            description={true} marginBottom={true}
                                            text={`If this Product should be of a different Category, select a new 
                                            Product Category. Otherwise, leave blank.`}
                                        />
                                        <FieldDivider type="break"/>
                                        <TextInput disabled label="Category" source="type_display_name"
                                                   className="input-inline input-320"/>
                                        <SelectFromListInput
                                            inAdminForm={true}
                                            source="new_product_type_id"
                                            resourceName="ProductType"
                                            listLabel="Select a New Category (optional)"
                                            itemPlural="Categories"
                                            listFilters={{
                                                active: true
                                            }}
                                            className="input-inline input-400"
                                            isRequired={false}
                                            returnType="string"
                                        />
                                        <FieldDivider type="break" margin="bottom"/>

                                        <TextInput label="Name of Product" source="title"
                                                   validate={validateName}
                                                   fullWidth={true}/>
                                        <TextInput label="Description of Product" source="description"
                                                   multiline fullWidth={true}/>
                                        <ProductImage renderType="edit" record={values} allowUpload={true}/>
                                        <TextInput label="Admin Notes" source="notes"
                                                   multiline fullWidth={true}/>
                                        <FieldDivider type="break" margin="bottom"/>

                                        <ProductLocationsInput record={record}/>
                                        <FieldDivider type="break" margin="bottom"/>

                                        <hr style={viewStyles.break}/>
                                        <Button
                                            type="button"
                                            variant="contained"
                                            color="primary"
                                            style={viewStyles.buttonInline}
                                            disabled={(validateForm(values) === false || submitting || success)}
                                            onClick={() => handleSubmit()}
                                        >
                                            Save Product
                                        </Button>
                                        <Button
                                            type="button"
                                            variant="contained"
                                            style={viewStyles.buttonInline}
                                            disabled={(submitting || success)}
                                            onClick={() => handleClose()}
                                        >
                                            Cancel
                                        </Button>
                                        {submitting &&
                                            <>
                                                <CircularProgress size={30} className={loadingClasses.root}/>
                                                <p className={loadingClasses.text}>Creating Product...</p>
                                            </>
                                        }
                                        {formMessage.message &&
                                            <p className={(formMessage.error) ? loadingClasses.textError : loadingClasses.text}>
                                                {formMessage.message}
                                            </p>
                                        }
                                    </form>
                                )
                            }}
                        >
                        </Form>
                    }
                </MuiDialogContent>
            </Dialog>
        </>
    )
};

export default ProductEditDialog;
