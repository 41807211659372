import {
    ORDER_QUERY_DEFAULTS,
    RECEIPT_QUERY_DEFAULTS,
    ORDER_RECEIPT_TABLE_FILTERS
} from '../global/orderReceiptDefaults';

/**
 * Determine if current runtime is local
 *
 * @type {boolean}
 */
export const isLocalhost = Boolean(
    window.location.hostname === 'localhost' ||
    window.location.hostname === '[::1]' ||
    window.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

/**
 * Load JSON file with environment variables
 *
 * @return {Promise<unknown>}
 */
export async function getEnvironmentConfig() {
    return fetch('/config.json')
        .then((response) => response.json())
        .then((data) => {
            return Promise.resolve(data);
        })
        .catch(error => {
            return Promise.reject(error);
        });
}

/**
 * Check for a blank or invalid value
 *
 * @param term
 * @return {boolean}
 */
export const isBlank = (term) => {
    return (term === undefined || term === null || term === '');
};

export const addPaginationAndSortToQuery = (queryObj) => {
    let q = '';
    if (queryObj.page) {
        q += addAnd(q) + 'page=' + queryObj.page;
    }
    if (queryObj.per_page) {
        q += addAnd(q) + 'per_page=' + queryObj.per_page;
    }
    if (queryObj.sort_by) {
        q += addAnd(q) + 'sort_by=' + queryObj.sort_by;
    }
    if (queryObj.sort_order) {
        q += addAnd(q) + 'sort_order=' + queryObj.sort_order;
    }
    return q;
};

export const addQuickFiltersToQuery = (queryObj) => {
    let q = '';
    // Assemble key/value pairs for table quick-filters
    ORDER_RECEIPT_TABLE_FILTERS.forEach((filter) => {
        // If this filter is populated:
        if (queryObj.filter[filter.key]) {
            // Do not include if the Filter By selection overrides it:
            if (queryObj.filter.filter_by && !filter.clearOnFilterBy.includes(queryObj.filter.filter_by)) {
                q += addAnd(q) + filter.key + '=' + queryObj.filter[filter.key];
            }
        }
    });
    return q;
};

export const buildSearchOrderReceiptQuery = (queryObj) => {
    let q = '?';

    // Assemble pagination and sorting
    q += addPaginationAndSortToQuery(queryObj);

    // Add Quick-filters
    q += addQuickFiltersToQuery(queryObj);

    // Add ID if the table does not already filter it
    if (!q.includes('s_id=')) {
        q += addAnd(q) + 's_id=' + queryObj.filter.filter_id;
    }

    return q;
};

/**
 * Assemble query string for Order/Receipt List calls
 *
 * @param queryObj
 * @param includeRange
 * @param includeFilters
 * @returns {string}
 */
export const buildOrderReceiptListQuery = (queryObj, includeRange = false, includeFilters = false) => {
    let q = '?';

    if (includeRange) {
        q += 'from=' + queryObj.from + '&to=' + queryObj.to;
    }
    // Assemble pagination and sorting
    q += addPaginationAndSortToQuery(queryObj);

    if (includeFilters) {
        q += addQuickFiltersToQuery(queryObj);
    }
    return q;
};

export const buildExportQuery = (queryObj) => {
    let q = '?';
    const filterBy = queryObj.filter_by || null;
    let includeRange = true;

    if (filterBy && (filterBy === 'sku' || filterBy === 'bill_ref')) {
        includeRange = false;
    }
    if (includeRange) {
        const dateRange = setDateRangeValues(queryObj.filter_from, queryObj.filter_to);
        if (queryObj.filter_from) {
            q += addAnd(q) + 'from=' + dateRange.fromWithTime;
        }
        if (queryObj.filter_to) {
            q += addAnd(q) + 'to=' + dateRange.toWithTime;
        }
    } else {
        const today = new Date();
        const todayStr = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
        q += addAnd(q) + 'from=2016-01-01 00:00:00&to=' + todayStr + ' 23:59:59';
    }
    // Add pagination and sorting
    q += addAnd(q) + 'page=1&per_page=10000&sort_by=' + queryObj.sort_by + '&sort_order=' + queryObj.sort_order;

    // Add top-level filters
    if (filterBy) {
        if (filterBy === 'client') {
            q += addAnd(q) + 'orgId=' + queryObj.filter_org_id;
        }
        if (filterBy === 'contact') {
            q += addAnd(q) + 'contactId=' + queryObj.filter_contact_id;
        }
        if (filterBy === 'status') {
            q += addAnd(q) + 'statusId=' + queryObj.filter_status_id;
        }
        if (filterBy === 'bill_ref') {
            q += addAnd(q) + 'billRef=' + queryObj.filter_bill_ref_id;
        }
        if (filterBy === 'sku') {
            q += addAnd(q) + 'sku=' + queryObj.filter_sku;
        }
    }
    // Add quick-filters
    ORDER_RECEIPT_TABLE_FILTERS.forEach((filter) => {
        if (queryObj[filter.key]) {
            // // Do not include if the Filter By selection overrides it:
            if (filterBy && !filter.clearOnFilterBy.includes(filterBy)) {
                q += addAnd(q) + filter.key + '=' + queryObj[filter.key];
            }
        }
    });

    return q;
};

export const buildOrderReceiptDetailedExportQuery = (queryObj) => {
    let q = '?';
    const filterBy = queryObj.filter_by || null;
    let includeRange = true;

    if (filterBy && (filterBy === 'sku' || filterBy === 'bill_ref')) {
        includeRange = false;
    }
    if (includeRange) {
        const dateRange = setDateRangeValues(queryObj.filter_from, queryObj.filter_to);
        if (queryObj.filter_from) {
            q += addAnd(q) + 'from=' + dateRange.fromWithTime;
        }
        if (queryObj.filter_to) {
            q += addAnd(q) + 'to=' + dateRange.toWithTime;
        }
    }
    // Add top-level filters
    if (filterBy) {
        if (filterBy === 'client') {
            q += addAnd(q) + 'orgId=' + queryObj.filter_org_id;
        }
        if (filterBy === 'contact') {
            q += addAnd(q) + 'contactId=' + queryObj.filter_contact_id;
        }
        if (filterBy === 'status') {
            q += addAnd(q) + 'statusId=' + queryObj.filter_status_id;
        }
        if (filterBy === 'bill_ref') {
            q += addAnd(q) + 'billRef=' + queryObj.filter_bill_ref_id;
        }
        if (filterBy === 'sku') {
            q += addAnd(q) + 'sku=' + queryObj.filter_sku;
        }
    }
    // Add quick-filters
    ORDER_RECEIPT_TABLE_FILTERS.forEach((filter) => {
        if (queryObj[filter.key]) {
            // // Do not include if the Filter By selection overrides it:
            if (filterBy && !filter.clearOnFilterBy.includes(filterBy)) {
                q += addAnd(q) + filter.key + '=' + queryObj[filter.key];
            }
        }
    });

    return q;
};

/**
 * Generate a UUID for payloads and unique keys (per React's unique-key requirement)
 */
export const generateUUID = () => {
    let dt = new Date().getTime();
    const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        const r = (dt + Math.random() * 16) % 16 | 0;
        dt = Math.floor(dt / 16);
        return (c === 'x' ? r : ((r & 0x3) | 0x8)).toString(16);
    });
    return uuid;
};

/**
 * Update an object via Object.assign
 *
 * @param oldObject
 * @param newValues
 * @return {any}
 */
export const updateObject = (oldObject, newValues) => {
    return Object.assign({}, oldObject, newValues)
};

/**
 * Copy an array
 *
 * @param array
 * @return {any}
 */
export const copyArray = (array) => {
    return JSON.parse(JSON.stringify(array))
};

/**
 * Combine two items into an array
 *
 * @param arr1
 * @param arr2
 * @return {*[]}
 */
export const combineArrays = (arr1, arr2) => {
    return [].concat(arr1, arr2);
};

/**
 * Convert bytes to a human-readable string
 *
 * @param bytes
 * @return {string}
 */
export const convertBytesToString = (bytes) => {
    let i = Math.floor(Math.log(bytes) / Math.log(1024)), sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB'];
    return (bytes / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + sizes[i];
};

/**
 * Sort array of objects alphanumerically
 *
 * @param arr
 * @param sortField
 * @param sortOrder
 */
export const sortArrayByKey = (arr, sortField, sortOrder = 'ASC') => {
    arr.sort(function (a, b) {
        return a[sortField].toString().toLowerCase().localeCompare(b[sortField].toString().toLowerCase());
    });
    if (sortOrder === 'DESC') {
        arr = arr.reverse();
    }
    return arr;
};

/**
 * Sort numbers
 *
 * @param a
 * @param b
 * @param sortOrder
 * @return {number}
 */
export const sortNumber = (a, b, sortOrder = 'ASC') => {
    return (sortOrder === 'DESC') ? b - a : a - b;
};

/**
 * Add commas to numerical strings
 *
 * @param nStr
 * @return {string}
 */
export const addCommas = (nStr) => {
    nStr += '';
    let x = nStr.split('.');
    let x1 = x[0];
    let x2 = x.length > 1 ? '.' + x[1] : '';
    let rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
        x1 = x1.replace(rgx, '$1,$2');
    }
    return x1 + x2;
};

/**
 * Pad numbers less than 10 with a leading zero
 *
 * @param num
 * @return {string}
 */
export const addLeadingZero = (num) => {
    return String('00' + num).slice(-2);
};

/**
 * Determine if a string contains a space
 *
 * @param s
 * @returns {boolean}
 */
export const hasSpace = (s) => {
    return (s.indexOf(' ') !== -1 || s.indexOf('%20') !== -1 || s.indexOf('%02') !== -1 || s.indexOf('%03') !== -1);
};

export const addAnd = (query) => {
    return (query[query.length - 1] === '?') ? '' : '&';
};

/**
 * Convert an incoming value to a currency for editing
 *
 * @param value
 * @return {{cents: number, dollars: number}}
 */
export const processToCurrency = (value) => {
    const currencyValue = (value && value !== '' && value !== 'NaN' && !isNaN(value))
        ? toCurrency(value)
        : toCurrency(0);
    const arr = currencyValue.split('.');
    return {
        dollars: parseInt(arr[0], 10),
        cents: parseInt(arr[1], 10),
        value: currencyValue
    };
};

/**
 * Convert value to number with two decimal places
 *
 * @param value
 * @param fromPennies
 * @return {string}
 */
export const toCurrency = (value, fromPennies = false) => {
    let valueToConvert = value;
    if (fromPennies) {
        valueToConvert = value * 0.01;
    }
    return addCommas(parseFloat(valueToConvert).toFixed(2));
};

/**
 * Format a datetime string from a Date object
 * @param date
 * @return {string}
 */
export const createDateTimeString = (date) => {
    // Format: YYYY-MM-DDTHH:MM:SSZ
    const dateObj = (date) ? date : new Date();
    return dateObj.getFullYear() + '-'
        + padDateString(dateObj.getMonth() + 1) + '-'
        + padDateString(dateObj.getDate()) + 'T'
        + padDateString(dateObj.getHours()) + ':'
        + padDateString(dateObj.getMinutes()) + ':'
        + padDateString(dateObj.getSeconds()) + 'Z';
};

/**
 * Assemble a human-readable date string
 *
 * @param datetime
 * @param order
 * @param withTime
 * @return {string}
 */
export const makeDateString = (datetime, order = 'm', withTime = true) => {
    const dateObj = new Date(datetime);
    const mo = dateObj.getMonth() + 1;
    const dy = dateObj.getDate();
    const yy = dateObj.getFullYear();
    let hr = dateObj.getHours();
    const ampm = (hr > 11) ? 'PM' : 'AM';
    if (hr === 0) {
        hr = 12;
    }
    if (hr > 12) {
        hr -= 12;
    }
    let mn = dateObj.getMinutes();
    let minVal = String('00' + mn).slice(-2);
    let dateStr;
    if (order === 'm') {
        dateStr = mo + '/' + dy + '/' + yy;
    } else if (order === 'd') {
        dateStr = dy + '/' + mo + '/' + yy;
    } else {
        dateStr = mo + '/' + dy + '/' + yy;
    }
    if (withTime) {
        dateStr += ', ' + hr + ':' + minVal + ' ' + ampm;
    }
    if (isNaN(yy) || isNaN(mo)) {
        dateStr = 'No Date';
    }
    return dateStr;
};

/**
 * Pad numbers less than 10 with a leading zero
 *
 * @param num
 * @return {string}
 */
export const padDateString = (num) => {
    return String('00' + num).slice(-2);
};

/**
 * Create additional values from a timestamp for rendering in tables or fields
 *
 * @param date
 * @param addOffset
 * @return {{date_item: Date, date_string: string}}
 */
export const setItemDateValues = (date, addOffset = false) => {
    const date_item = new Date(date);
    if (addOffset) {
        const offset = date_item.getTimezoneOffset() * 60000;
        date_item.setTime(date_item.getTime() + offset);
    }
    const date_string = makeDateString(date_item.toString(), 'm', true);
    return {
        date_item: date_item,
        date_string: date_string
    }
};

/**
 * Return a date moved forward by time zone offset
 *
 * @param date
 * @returns {*}
 */
export const subtractTimeZoneOffset = (date) => {
    const offset = date.getTimezoneOffset() * 60000;
    date.setTime(date.getTime() - offset);
    return date;
};

/**
 * Return a date moved backward by time zone offset
 *
 * @param date
 * @returns {*}
 */
export const addTimeZoneOffset = (date) => {
    const offset = date.getTimezoneOffset() * 60000;
    date.setTime(date.getTime() + offset);
    return date;
};

export const subtractMonths = (date, months) => {
    date.setMonth(date.getMonth() - months);
    return date;
}

export const getDefaultDateRanges = () => {
    const today = new Date();
    const lastMonth = subtractMonths(new Date(), 1);
    const to = today.getFullYear() + '-' + padDateString(today.getMonth() + 1) + '-' + padDateString(today.getDate());
    let lastMonthStr = padDateString(lastMonth.getMonth() + 1);
    let lastDateStr = padDateString(lastMonth.getDate());
    if (lastMonthStr === '02' && lastDateStr === '29') {
        lastDateStr = '28';
    }
    let monthBack = lastMonth.getFullYear() + '-' + lastMonthStr + '-' + lastDateStr;
    return {
        from: '2016-01-01',
        to: to.toString(),
        monthBack: monthBack
    };
};

export const formatDateToUTCWithOffset = (date) => {
    const offset = date.getTimezoneOffset();
    const utcDate = new Date(date.getTime() + offset * 60000);
    return utcDate.toISOString();
};

export const setDateRangeValues = (from, to) => {
    const defaultDateRanges = getDefaultDateRanges();

    // Set from request to UTC date + time zone offset
    const fromValue = (from) ? from : defaultDateRanges.from;
    const fromDate = new Date(fromValue);
    const fromUTC = formatDateToUTCWithOffset(fromDate);
    const fromWithTime = fromUTC.substring(0, 10) + ' ' + fromUTC.substring(11, 19);

    // Set to request to end of UTC date + time zone offset
    const toValue = (to) ? to : defaultDateRanges.to;
    const toDate = new Date(toValue);
    toDate.setUTCHours(23);
    toDate.setUTCMinutes(59);
    toDate.setUTCSeconds(59);
    const toUTC = formatDateToUTCWithOffset(toDate);
    const toWithTime = toUTC.substring(0, 10) + ' ' + toUTC.substring(11, 19);

    return {
        from: fromValue,
        fromWithTime: fromWithTime,
        to: toValue,
        toWithTime: toWithTime
    };
};

export const assembleDefaultQueryString = (resource) => {
    const defaultDateRanges = getDefaultDateRanges();
    let p = {
        filter: {},
    };
    if (resource === 'Order') {
        p.filter.filter_list_view = ORDER_QUERY_DEFAULTS.filter_list_view;
        p.filter.filter_by = ORDER_QUERY_DEFAULTS.filter_by;
        p.filter.filter_status_id = ORDER_QUERY_DEFAULTS.filter_status_id;
        p.filter.filter_from = defaultDateRanges.monthBack;
        p.filter.filter_to = defaultDateRanges.to;
        p.per_page = ORDER_QUERY_DEFAULTS.per_page;
        p.sort_by = ORDER_QUERY_DEFAULTS.sort_by;
        p.sort_order = ORDER_QUERY_DEFAULTS.sort_order;
    }
    if (resource === 'Receipt') {
        p.filter.filter_list_view = RECEIPT_QUERY_DEFAULTS.filter_list_view;
        p.filter.filter_by = RECEIPT_QUERY_DEFAULTS.filter_by;
        p.filter.filter_from = defaultDateRanges.monthBack;
        p.filter.filter_to = defaultDateRanges.to;
        p.per_page = RECEIPT_QUERY_DEFAULTS.per_page;
        p.sort_by = RECEIPT_QUERY_DEFAULTS.sort_by;
        p.sort_order = RECEIPT_QUERY_DEFAULTS.sort_order;
    }

    const encodedFilter = encodeURIComponent(JSON.stringify(p.filter));
    return '?filter=' + encodedFilter + '&page=1&per_page=' + p.per_page + '&sort=' + p.sort_by + '&order=' + p.sort_order;
};

export const formatLaborTime = (data) => {
    let laborTimeObj = {
        time: '00:00',
        hours: 0,
        mins: 0
    };
    if (data.labor_time) {
        laborTimeObj.time = (data.labor_time) ? data.labor_time.replace('.', ':') : '00:00';
        const timeArr = data.labor_time.split(':');
        laborTimeObj.hours = (timeArr[0]) ? parseInt(timeArr[0], 10) : 0;
        laborTimeObj.mins = (timeArr[1]) ? parseInt(timeArr[1], 10) : 0;
    }
    return {
        time: laborTimeObj.time,
        hours: laborTimeObj.hours,
        mins: laborTimeObj.mins
    }
};

export const calculateBillingCosts = (type, item) => {
    const courierCost = parseFloat(item?.courier_cost ?? 0);
    const laborCost = parseFloat(item?.labor_cost ?? 0);
    const materialCost = parseFloat(item?.material_cost ?? 0);
    let total = 0;
    if (type === 'receipt') {
        const receivingCost = parseFloat(item?.receiving_cost ?? 0);
        total = courierCost + laborCost + materialCost + receivingCost;
        return toCurrency(total, false);
    }
    const shippingCost = parseFloat(item?.shipping_cost ?? 0);
    total = courierCost + laborCost + materialCost + shippingCost;
    return toCurrency(total, false);
};

export const formatBillingCosts = (data) => {
    const courierCost = (data.courier_cost) ? data.courier_cost.replace(',', '') : 0;
    const courierCurrency = (courierCost) ? processToCurrency(courierCost) : {value: 0};
    const laborCost = (data.labor_cost) ? data.labor_cost.replace(',', '') : 0;
    const laborCurrency = (laborCost) ? processToCurrency(laborCost) : {value: 0};
    const materialCost = (data.material_cost) ? data.material_cost.replace(',', '') : 0;
    const materialCurrency = (materialCost) ? processToCurrency(materialCost) : {value: 0};
    const receivingCost = (data.receiving_cost) ? data.receiving_cost.replace(',', '') : 0;
    const receivingCurrency = (receivingCost) ? processToCurrency(receivingCost) : {value: 0};
    const shippingCost = (data.shipping_cost) ? data.shipping_cost.replace(',', '') : 0;
    const shippingCurrency = (shippingCost) ? processToCurrency(shippingCost) : {value: 0};
    return {
        courier: courierCost,
        courier_str: courierCurrency.value,
        labor: laborCost,
        labor_str: laborCurrency.value,
        material: materialCost,
        material_str: materialCurrency.value,
        receiving: receivingCost,
        receiving_str: receivingCurrency.value,
        shipping: shippingCost,
        shipping_str: shippingCurrency.value
    }
};

export const processProductTypeList = (list, type) => {
    if (type === 'parent') {
        // Return only Product Categories without a parent Category
        return list.filter(item => (item.parent_type_id === null || item.parent_type_id === undefined || item.parent_type_id === ''));
    }
    if (type === 'child') {
        // Return only Product Categories with a parent Category
        return list.filter(item => (item.parent_type_id && item.parent_type_id > 0));
    }
    return list;
};

export const stripWhiteSpace = (str) => {
    if (!str) {
        return str;
    }
    return str.replace(/\s\s+/g, ' ').trim();
};
