import {buildOrderReceiptListQuery, buildSearchOrderReceiptQuery, setDateRangeValues} from './utils/dataFunctions';
import {RECEIPT_QUERY_DEFAULTS} from './global/orderReceiptDefaults';

/**
 * Product Receipt Resources for API
 *      Receipt:
 *          Get List by Filter
 *          Get One
 *          Update
 *      Receipt: Start (initialize)
 *      Receipt: Item(s)
 *          Get List by Receipt
 *          Get One
 *          Add One
 *          Update Qty
 *      Receipt: Create (finish)
 *      Receipts by Organization
 */

export const RESOURCES = {
    'Receipt': {
        GET_LIST: function (params) {
            const filter = params.params.filter;
            const filterBy = params.params.filter.filter_by;
            const dateRange = setDateRangeValues(filter.filter_from, filter.filter_to);
            const pagination = params.params.pagination;
            const sort = params.params.sort;
            const queryObj = {
                page: pagination?.page ?? RECEIPT_QUERY_DEFAULTS.page,
                per_page: pagination?.perPage ?? RECEIPT_QUERY_DEFAULTS.per_page,
                sort_by: sort?.field ?? RECEIPT_QUERY_DEFAULTS.sort_by,
                sort_order: sort?.order ?? RECEIPT_QUERY_DEFAULTS.sort_order,
                filter: filter
            };
            let query = buildOrderReceiptListQuery(queryObj, false, true);
            let uri;

            if (filterBy === 'client' && filter.filter_org_id) {
                // Get Receipts by selected Organization ID and Date Range
                queryObj.from = dateRange.fromWithTime;
                queryObj.to = dateRange.toWithTime;
                query = buildOrderReceiptListQuery(queryObj, true, true);
                uri = '/api/v1/mgmt/receipts/organization/' + filter.filter_org_id + query;
            } else if (filterBy === 'id' && filter.filter_id) {
                // Get Receipts by selected Receipt ID
                query = buildSearchOrderReceiptQuery(queryObj);
                uri = '/api/v1/mgmt/receipts/all' + query;
            } else if (filterBy === 'sku' && filter.filter_sku) {
                // Get Receipts by selected Product SKU
                uri = '/api/v1/mgmt/receipts/sku/' + filter.filter_sku + query;
            } else if (filterBy === 'date_range' && dateRange.fromWithTime && dateRange.toWithTime) {
                // Get all Orders by Date Range
                queryObj.from = dateRange.fromWithTime;
                queryObj.to = dateRange.toWithTime;
                query = buildOrderReceiptListQuery(queryObj, true, true);
                uri = '/api/v1/mgmt/receipts/all' + query;
            } else {
                queryObj.from = dateRange.fromWithTime;
                queryObj.to = dateRange.toWithTime;
                query = buildOrderReceiptListQuery(queryObj, true, true);
                uri = '/api/v1/mgmt/receipts/all' + query;
            }
            return {
                'method': 'GET',
                'title': 'Receipts',
                'uri': localStorage.getItem('server') + uri
            }
        },
        GET_ONE: function (params) {
            return {
                'method': 'GET',
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/receipt/' + params.id
            }
        },
        UPDATE: function (params) {
            return {
                'method': 'POST',
                "data": params,
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/receipt/update'
            }
        },
        DELETE: function (params) {
            let id = (params && params.id) ? params.id : null;
            return {
                'method': 'DELETE',
                'data': params,
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/receipt/' + id
            };
        }
    },
    'ReceiptStart': {
        CREATE: function (params) {
            return {
                'method': 'PUT',
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/receipt/start/' + params.id
            }
        }
    },
    'ReceiptItem': {
        GET_LIST: function (params) {
            let id = (params && params.id) ? params.id : null;
            return {
                'method': 'GET',
                'title': 'Receipt Items',
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/receiptItems/' + id
            }
        },
        GET_ONE: function (params) {
            let id = (params && params.id) ? params.id : null;
            return {
                'method': 'GET',
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/receiptItem/' + id
            }
        },
        CREATE: function (params) {
            return {
                'method': 'POST',
                "data": params.data,
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/receiptItem/add'
            }
        },
        UPDATE: function (params) {
            return {
                'method': 'POST',
                "data": params.data,
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/receiptItem/update'
            }
        },
        DELETE: function (params) {
            let id = (params.data && params.data.id) ? params.data.id : null;
            return {
                'method': 'DELETE',
                "data": {},
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/receiptItem/' + id
            }
        }
    },
    'ReceiptItemNotes': {
        UPDATE: function (params) {
            return {
                'method': 'POST',
                "data": params.data,
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/receiptItem/updateNotes'
            }
        }
    },
    'ReceiptCreate': {
        CREATE: function (params) {
            return {
                'method': 'POST',
                "data": params.data,
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/receipt/create'
            }
        }
    },
    'ReceiptExport': {
        GET_LIST: function (params) {
            return {
                'method': 'GET',
                'title': 'Receipts',
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/receipts/all' + params
            }
        }
    },
    'ReceiptDetailedExport': {
        GET_LIST: function (params) {
            return {
                'method': 'GET',
                'title': 'Receipts',
                'uri': localStorage.getItem('server') + '/api/v1/mgmt/receipts/detailExport' + params
            }
        }
    },
};

export default (type, resource, params) => {

    /**
     * @param {string} type Request type, e.g. GET_LIST
     * @param {string} resource Resource name, e.g. "ReceiptStart"
     * @param {Object} payload Request parameters. Depends on request type
     * @returns {Object} RESOURCES object
     */
    return (type, resource, params) => {
        return RESOURCES;
    }
}
