import React, {useCallback, useEffect, useState} from 'react';
import axios from 'axios';
import {RESOURCES as resources} from '../receiptResources';
import {sortArrayByKey} from '../utils/dataFunctions';
import {postItem} from '../utils/apiReceiptFunctions';
import {getGetHeaders} from '../utils/headers';
import FieldDescription from './FieldDescription';
import FieldDivider from './FieldDivider';
import ListLoading from './ListLoading';
import ReceiptEditAddItemContainer from './ReceiptEditAddItemContainer';
import ReceiptItemsList from './ReceiptItemsList';
import ReceiptPrintLabel from './ReceiptPrintLabel';

/**
 * A component for retrieving and displaying a Product List by Receipt ID
 *
 * @param view
 * @param receiptData
 * @param receiptId
 * @param forPrint
 * @param hasDelete
 * @param hasEditName
 * @param hasEditQty
 * @param props
 * @return {JSX.Element}
 * @constructor
 */
const ReceiptItemListViewContainer = ({
                                          view,
                                          receiptData,
                                          receiptId,
                                          forPrint = false,
                                          hasDelete = false,
                                          hasEditName = false,
                                          hasEditQty = true,
                                          ...props
                                      }) => {

    const [itemList, setItemList] = useState({
        products: []
    });
    const [loading, setLoading] = useState(false);
    const [saving, setSaving] = useState(false);
    const [saveError, setSaveError] = useState(null);
    const [updateComplete, setUpdateComplete] = useState(false);
    const [productToUpdate, setProductToUpdate] = useState(null);

    const fetchListData = useCallback(() => {
        // Fetch Products by Receipt ID
        let id = receiptId;
        if (!id) {
            id = (props.id) ? props.id : null;
        }
        if (id && !updateComplete) {
            const fetchItems = () => {
                const params = {
                    active: true,
                    id: id,
                    params: {
                        filter: {},
                        pagination: {page: 1, perPage: 10000},
                        sort: {field: "title", order: "ASC"}
                    }
                };

                const cfg = resources.ReceiptItem.GET_LIST(params);
                axios({
                    method: 'GET',
                    url: cfg.uri,
                    headers: getGetHeaders(),
                    maxBodyLength: Infinity,
                    maxContentLength: Infinity
                }).then((response) => {
                    if (response.data && response.data.length > 0) {
                        for (let i = 0; i < response.data.length; i++) {
                            const product = response.data[i];
                            // Add Number of boxes if not included
                            if (!product.num_boxes) {
                                product.num_boxes = 1;
                            }
                            // Sort Warehouse Locations for each item
                            product.first_warehouse_location = '';
                            if (product.warehouse_locations && product.warehouse_locations.length > 0) {
                                product.warehouse_locations = sortArrayByKey(product.warehouse_locations, 'location', 'ASC');
                                // Store first Warehouse Location for table sorting
                                product.first_warehouse_location = product.warehouse_locations[0].location;
                            }
                        }
                        // Sort Products by first Warehouse Location
                        response.data = sortArrayByKey(response.data, 'first_warehouse_location', 'ASC');
                        setItemList(itemList => ({...itemList, products: response.data}));
                    } else {
                        setItemList({products: []});
                    }
                    setUpdateComplete(true);
                    setLoading(false);
                }).catch((error) => {
                    console.log('error response for Products: ', error);
                    if (error.response) {
                        // Server response outside 2xx
                    } else if (error.request) {
                        // No response
                    }
                    setItemList({products: []});
                    setLoading(false);
                });
            };

            // If a Receipt ID becomes available or has changed, or if a refresh is needed, fetch Products
            if ((receiptId || props.id) || !updateComplete) {
                setLoading(true);
                fetchItems();
            }
        }
    }, [receiptId, props.id, updateComplete]);

    // Effect: Fetch Products by Receipt ID
    useEffect(
        () => {
            // If a Receipt ID becomes available or has changed, or if a refresh is needed, fetch Products
            if ((receiptId || props.id) || !updateComplete) {
                return fetchListData();
            }

        }, [receiptId, props.id, updateComplete, fetchListData]
    );

    const handleAddItem = (e) => {
        const item = (e && e.item) ? e.item : null;
        let id = (props.id) ? parseInt(props.id, 10) : null;
        let qty = (item && item.qty_requested) ? item.qty_requested : 0;
        if (item && item.id) {
            setSaving(true);
            const params = {
                id: item.id,
                receipt_id: id,
                product_id: item.product_id,
                qty: qty,
                notes: item.notes,
                num_boxes: item.num_boxes
            };

            postItem('addItem', params)
                .then(result => {
                    if (result && result.product_id) {
                        // Display message and reset item list
                        setSaveError(null);
                    }
                })
                .then(() => {
                    setSaving(false);
                    setUpdateComplete(false);
                    fetchListData();
                })
                .catch(error => {
                    // Display message
                    setSaving(false);
                    setSaveError('Sorry, there was an error adding this Item.');
                });
        }
    };

    const handleUpdateQty = (e) => {
        const item = (e && e.item) ? e.item : null;
        const newQty = (e && e.new_qty) ? e.new_qty : null;
        const newNumBoxes = (e && e.num_boxes) ? e.num_boxes : item.num_boxes;
        let id = (props.id) ? parseInt(props.id, 10) : null;
        if (item && newQty && id) {
            // Post the update for the requested Product
            setSaving(true);
            const params = {
                id: item.id,
                receipt_id: id,
                product_id: item.product_id,
                qty: item.qty,
                new_qty: newQty,
                num_boxes: newNumBoxes
            };
            postItem('updateItem', params)
                .then(result => {
                    if (result && result.product_id) {
                        // Display message and reset item list
                        setSaving(false);
                        setSaveError(null);
                        setUpdateComplete(false);
                        fetchListData();
                    }
                })
                .catch(error => {
                    // Display message
                    setSaving(false);
                    setSaveError('Sorry, there was an error updating this Item.');
                });
        }
    };

    const handleEditProduct = () => {
        setSaving(false);
        setSaveError(null);
        setUpdateComplete(false);
        fetchListData();
        setProductToUpdate(null);
    };

    const handleEditProductName = (product) => {
        setSaving(false);
        setSaveError(null);
        setUpdateComplete(false);
        fetchListData();
        setProductToUpdate(product);
        setTimeout(function () {
            setProductToUpdate(null);
        }, 100);
    };

    const handleUpdateNotes = (e) => {
        const item = (e && e.item) ? e.item : null;
        if (item) {
            // Post the update for the requested Product
            setSaving(true);
            const params = {
                id: item.id,
                notes: item.notes
            };
            postItem('updateNotes', params)
                .then(result => {
                    if (result && result.product_id) {
                        // Display message and reset item list
                        setSaving(false);
                        setSaveError(null);
                        setUpdateComplete(false);
                    }
                })
                .catch(error => {
                    console.log('Product Update Error: ', error);
                    // Display message
                    setSaving(false);
                    setSaveError('Sorry, there was an error updating this Item.');
                });
        }
    };

    const handleDelete = (id) => {
        const itemId = (id) ? id : null;

        if (itemId) {
            // Post the update for the requested Product
            setSaving(true);
            const params = {
                id: itemId
            };
            postItem('deleteItem', params)
                .then(result => {
                    if (result && result.message && result.message.indexOf('deleted') !== -1) {
                        // Display message and reset item list
                        setSaving(false);
                        setSaveError(null);
                        setUpdateComplete(false);
                        fetchListData();
                    }
                })
                .catch(error => {
                    setSaving(false);
                    setSaveError('Sorry, there was an error deleting this Item.');
                });
        }
    };

    return (
        <>
            {!forPrint &&
                <FieldDescription instructions={true} margin="bottom"
                                  text={`If the quantity of received Products should be adjusted, or if a Product was not received, edit or delete below. If more Products should be added to this Receipt, add them below.`}
                />
            }
            {loading &&
                <ListLoading text="Loading Products..."/>
            }
            <ReceiptItemsList
                itemList={itemList.products}
                hasEditName={hasEditName}
                hasEditProduct={(!forPrint)}
                hasEditQty={hasEditQty}
                hasEditNotes={(view === 'edit')}
                hasDelete={hasDelete}
                hasInfo={(!forPrint)}
                hasRemove={false}
                saving={saving}
                error={saveError}
                onEditQtyFunc={(e) => handleUpdateQty(e)}
                onEditNameFunc={(e) => handleEditProductName(e)}
                onEditProductFunc={(e) => handleEditProduct(e)}
                onEditNotesFunc={(e) => handleUpdateNotes(e)}
                onDeleteFunc={(e) => handleDelete(e)}
            />

            {view === 'edit' && !forPrint &&
                <>
                    <FieldDivider type="break" margin="bottom"/>
                    <FieldDescription instructions={true} margin="bottom"
                                      text={`Add more Products below if desired. If a Product is already in the list, update its quantity instead.`}
                    />
                    <ReceiptEditAddItemContainer
                        record={receiptData}
                        onAddFunc={(e) => handleAddItem(e)}
                        onEditNameFunc={(e) => handleEditProductName(e)}
                        refresh={productToUpdate}
                    />
                    <FieldDivider type="break"/>
                </>
            }

            {receiptData && itemList.products.length > 0 &&
                <ReceiptPrintLabel data={receiptData} items={itemList.products}/>
            }
        </>
    );
}

export default ReceiptItemListViewContainer;
